<template>
	<div id="liveEdit" class="el-content">
		<page-freight :is-shop="1"></page-freight>
	</div>
</template>

<script>
	import pageFreight from '@/components/layout/set/page-freight.vue'
	export default {
		components: {
			pageFreight
		},
	}
</script>

<style lang="scss">

</style>
