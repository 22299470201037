<template>
    <div class="page-freight">
        <router-link to="/set/frieght/edit">
            <a-button class="sds-fight-btn" type="primary" v-if="isShop == 0">
                <i class="ri-add-line"></i>新增运费模板
            </a-button>
        </router-link>
        <router-link to="/many_store/set/frieght/edit">
            <a-button class="sds-fight-btn" type="primary" v-if="isShop == 1">
                <i class="ri-add-line"></i>新增运费模板
            </a-button>
        </router-link>
        <a-table row-key="id" :pagination="false" :data-source="info.list" :columns="[
            {title:'ID',dataIndex:'id'},
            {title:'运费模板名称',dataIndex:'name'},
            {title:'是否默认',dataIndex:'is_default',slots:{customRender:'is_default'}},
            {title:'最后更新时间',dataIndex:'update_time'},
            {title:'操作',dataIndex:'action',slots:{customRender:'action'}},
        ]" class="mt12">
            <template #is_default="{record}">
                <a-tag :color="record.is_default == 1 ? '#00CC66':'#999'">
                    {{record.is_default == 1 ? '是':'否'}}
                </a-tag>
            </template>
            <template #action="{record}">
                <a-space>
                    <router-link :to="{path:'/set/frieght/edit',query:{id:record.id}}">
                        <kd-button type="primary" 
                            title="编辑" 
                            icon="ri-edit-line"
                            v-if="isShop == 0"  
                            v-has="{action:'/set/frieght/edit',plat:isShop}">
                        </kd-button>
                    </router-link>
                    <router-link :to="{path:'/many_store/set/frieght/edit',query:{id:record.id}}">
                        <kd-button type="primary" 
                            title="编辑" 
                            icon="ri-edit-line"
                            v-if="isShop == 1"  
                            v-has="{action:'/set/frieght/edit',plat:isShop}">
                        </kd-button>
                    </router-link>
                    <kd-button type="danger" title="删除" 
                        icon="ri-delete-bin-5-line" 
                        v-has="{action:'wxapp_delivery_del_frieght',plat:isShop}" 
                        @click="delFrieight(record.id)">
                    </kd-button>
                </a-space>
            </template>
        </a-table>
        <div class="pager">
            <a-pagination
                show-size-changer
                :default-current="info.page"
                :total="info.count"
                @showSizeChange="(p,e)=>{getFrieghtList(info.page,e)}"
                @change="(e)=>{getFrieghtList(e,info.limit)}"
            />
        </div>
    </div>
</template>
<script>
import { reactive, toRefs } from 'vue'
import setModel from '@/api/set.js'
import commonModel from '@/api/common.js'
export default {
    name:"page-freight",
    props:{
        isShop:{
            type:Number,
            default:0
        }
    },
    setup() {
        const _d = reactive({
            info:{
                list:[],
                page:1,
                limit:10,
                size:10
            }
        })
        getFrieghtList(1,10)
        function getFrieghtList(page,limit){
            setModel.getFreight(page,limit,res=>{
                _d.info.list = res.list
                _d.info.count = res.count
            })
        }

        function delFrieight(id){
            commonModel.deleteDataList(id,36,"确认删除该运费规则信息吗").then(()=>{
                getFrieghtList(_d.info.page,_d.info.limit)
            })
        }

        return{
            ...toRefs(_d),
            delFrieight,
            getFrieghtList
        }
    },
}
</script>